import User from 'types/User';
import get from 'lodash/get';
import * as Sentry from '@sentry/react';

let loggedIn = false;

const thirdPartyLogin = (user: User) => {
  if (loggedIn) return;
  loggedIn = true;
  sentry(user);
  fullStory(user);
  zendesk(user);
};

const fullStory = (user: User) => {
  const FS = window['FS'];
  if (FS) {
    const userVars = {
      displayName: user.email,
      account_str: user.currentSuggester?.accountName,
    };
    FS.identify(`User${user.id}`, userVars);
  }
};

const sentry = (user: User) => {
  if (process.env.REACT_APP_ENABLE_SENTRY !== 'true') return;

  Sentry.setUser({
    id: user.id.toString(),
    email: user.email,
  });
  Sentry.setTag('suggester.id', get(user, 'currentSuggester.id'));
  Sentry.setTag('suggester.brand', get(user, 'currentSuggester.brand'));
  Sentry.setTag('suggester.name', get(user, 'currentSuggester.accountName'));
};

const zendesk = (_user: User) => {
  try {
    const zE = window['zE'];
    if (!zE) return;
    zE('webWidget', 'show');
  } catch (error) {
    console.error(
      'An error occurred while attempting to show the Zendesk webWidget:',
      error
    );
  }
};

export default thirdPartyLogin;
