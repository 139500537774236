import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import Attachment from 'types/Attachment';
import FaceType from 'types/Face';
import { useIntl } from 'hooks';
import { ScanForFaces } from './components';
import * as FACE_FIELDS from 'graphql/fragments/FaceFields.graphql';
import { useQuery } from '@apollo/react-hooks';
import { IconCheckmark, IconLoader, IconQuestionMark } from 'icons';
import clsx from 'clsx';
import Tooltip from 'components/Tooltip';

interface Props {
  attachment: Attachment;
}

const LOAD_FACES = gql`
  query LoadFaces($id: ID!) {
    attachment(id: $id) {
      id
      scannedForFaces
      faces: highQualityFaces {
        ...FaceFields
      }
    }
  }

  ${FACE_FIELDS}
`;

interface QueryData {
  attachment: { scannedForFaces: boolean; faces: FaceType[] };
}

export default function Faces({ attachment }: Props) {
  const { data, loading } = useQuery<QueryData>(LOAD_FACES, {
    variables: { id: attachment.id },
  });

  const faces: FaceType[] = get(data, 'attachment.faces') || [];

  const scannedForFaces = get(data, 'attachment.scannedForFaces');

  return (
    <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center">
      <div
        className="relative h-0 w-full"
        style={{ paddingBottom: `${attachment.aspectRatio * 100}%` }}
      >
        {faces.map((face) => (
          <Face key={face.id} attachment={attachment} face={face} />
        ))}

        {!loading && !scannedForFaces && attachment.viewerCanEdit && (
          <ScanForFaces id={attachment.id} />
        )}

        {loading && (
          <div className="absolute bottom-0 right-0 px-1 opacity-50">
            <IconLoader className="h-2 w-2" />
          </div>
        )}
      </div>
    </div>
  );
}

interface FaceProps {
  attachment: Attachment;
  face: FaceType;
}

const PADDING = 5;
const ICON_SIZE = 20;

function Face({ attachment, face }: FaceProps) {
  const { top, left, width, height, mediaAppearance } = face;
  const { t } = useIntl();
  return (
    <React.Fragment key={face.id}>
      {/* Box around the face */}
      <div
        className={clsx(
          'absolute z-10 rounded border-2 border-solid',
          mediaAppearance ? 'border-blue' : 'border-white'
        )}
        style={{
          top: `calc(${top * 100}% - ${PADDING}px)`,
          left: `calc(${left * 100}% - ${PADDING}px)`,
          width: `calc(${width * 100}% + ${PADDING * 2}px)`,
          height: `calc(${height * 100}% + ${PADDING * 2}px)`,
        }}
      />
      {/* Label */}
      <div
        className={clsx(
          'absolute z-[11] rounded-full p-0.5',
          mediaAppearance ? 'bg-blue' : 'bg-white'
        )}
        style={{
          top: `calc(${top * 100}% - ${PADDING}px - ${ICON_SIZE / 2}px)`,
          left: `calc(${left * 100}% + ${width * 100}% - ${
            ICON_SIZE / 2
          }px + ${PADDING}px)`,
          width: `${ICON_SIZE}px`,
          height: `${ICON_SIZE}px`,
        }}
      >
        <Tooltip
          content={
            mediaAppearance
              ? mediaAppearance.publisher.name
              : t('Faces__NotRecognized')
          }
          theme="socialieDark"
          placement="top"
        >
          <div>
            {mediaAppearance ? (
              <IconCheckmark className="block h-full w-full text-white" />
            ) : (
              <IconQuestionMark className="block h-full w-full text-dark" />
            )}
          </div>
        </Tooltip>
      </div>
    </React.Fragment>
  );
}
