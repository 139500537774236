import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://08a1fcff0d92253ef9ac2837b28fbb5f@sentry.lon.bitshelter.com/3',

    environment:
      process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE || undefined,

    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/api\.socialieapps\.com\/(?!socket.io).*/,
      /^https:\/\/api\.socialie\.com\/(?!socket.io).*/,
      /^https:\/\/api\.socialie\.dev\/(?!socket.io).*/,
    ],
    // Session Replay
    replaysSessionSampleRate: Number(
      process.env.REACT_APP_SENTRY_SESSION_REPLAY_SAMPLE_RATE || 0
    ),
    replaysOnErrorSampleRate: 1.0,
  });
}
