import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import * as Sentry from '@sentry/react';

interface Props {
  error: any;
}

const Wrapper = styled.div`
  pre {
    background: ${colors.grey1};
    padding: ${grid(2)};
    width: 100%;
    max-height: 400px;
    overflow: auto;
    text-align: left;
    color: ${colors.white};
    font-size: 14px;
  }
`;

const ErrorDebug = ({ error }: Props) => {
  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    Sentry.captureException(error);
  }

  return (
    <Wrapper data-testid="error-debug">
      <FormattedMessage id="Global__GraphQLError" />

      {process.env.NODE_ENV !== 'production' && (
        <>
          <br />
          Since we're not in production, here's the error:
          <br />
          <pre>{JSON.stringify(error, null, ' ')}</pre>
        </>
      )}
    </Wrapper>
  );
};

export default ErrorDebug;
