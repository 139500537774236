import React, { useState } from 'react';
import moment from 'moment-timezone';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import includes from 'lodash/includes';
import { Route } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import MediaLibraryFilter from 'types/MediaLibraryFilter';
import Attachment from 'types/Attachment';
import RelayConnection from 'types/RelayConnection';
import { useSelectable, useFilterManager, useIntl } from 'hooks';
import { updateQuery, mapNodes, routes } from 'helpers';
import {
  AttachmentList,
  BackToTopButton,
  ErrorDebug,
  IndexPage,
  Loader,
  Form,
} from 'components';
import {
  AttachmentModal,
  MediaLibraryFilters,
  MediaLibraryFilterPills,
  MediaLibraryMassActions,
  NewContentNotification,
  MediaLibraryEmptyState,
  MediaLibraryStorage,
  MediaLibraryUploadButton,
} from './components';
import ATTACHMENTS_QUERY from 'graphql/queries/attachments.graphql';
import FILTER_QUERY from 'graphql/queries/MediaLibraryFilterData.graphql';
import { MediaLibrarySubNavBar } from 'screens/MediaLibrary/components';
import {
  IconGrid,
  IconGridFluid,
  IconGridFluidOutline,
  IconGridOutline,
} from 'icons';
import { NetworkStatus } from 'apollo-client';
import MediaLibraryFilterData from 'types/MediaLibraryFilterData';
import useListenForAttachmentUpdates from 'hooks/useListenForAttachmentUpdates';

interface QueryData {
  attachments: RelayConnection<Attachment>;
}

interface QueryVars {
  filter: Partial<MediaLibraryFilter>;
  cursor?: string;
}

const defaultFilter: Readonly<MediaLibraryFilter> = {
  sources: [],
  mediaType: [],
  suggested: '2',
  query: '',
  publishers: [],
  publisherTags: [],
  submitters: [],
  suggesters: [],
  suggestionRecipients: [],
  attachmentAlbums: [],
  suggestionAlbums: [],
  suggesterPublishers: [],
  mediaAppearancesOnly: null,
  noMediaAppearances: null,
  createdAt: {
    dateRange: 'all',
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
  canMonetize: false,
  uploaders: [],
};

export const SelectableContext = React.createContext<
  ReturnType<typeof useSelectable>
>({} as ReturnType<typeof useSelectable>);

export const FilterContext = React.createContext<{
  filter: MediaLibraryFilter;
  debouncedFilter: MediaLibraryFilter;
  updateFilter: (updates: Partial<MediaLibraryFilter>) => void;
}>({
  filter: {} as MediaLibraryFilter,
  debouncedFilter: {} as MediaLibraryFilter,
  updateFilter: (updates) => {},
});

export default function MediaLibraryIndex() {
  const { t } = useIntl();

  useListenForAttachmentUpdates();

  // Manage the view
  const [thumbnailStyle, setThumbnailStyle] = useState<'compact' | 'full'>(
    localStorage.getItem('mediaLibraryThumbnailStyle') === 'compact'
      ? 'compact'
      : 'full'
  );

  const handleUpdateThumbnailStyle = (nextStyle: typeof thumbnailStyle) => {
    localStorage.setItem('mediaLibraryThumbnailStyle', nextStyle);
    setThumbnailStyle(nextStyle);
  };

  // Set up filters
  const filterQueryResult = useQuery<MediaLibraryFilterData>(FILTER_QUERY);

  const { filter, debouncedFilter, updateFilter } = useFilterManager(
    defaultFilter,
    true
  );

  const isFiltered = !isEqual(
    omit(filter, 'query'),
    omit(defaultFilter, 'query')
  );

  // Load attachments
  const { data, error, fetchMore, refetch, networkStatus } = useQuery<
    QueryData,
    QueryVars
  >(ATTACHMENTS_QUERY, {
    variables: { filter: debouncedFilter },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  // We want to go into loading state for initial load and refetch, but not
  // fetchMore
  const loading = includes([1, 2, 4], networkStatus);

  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const hasNextPage = get(data, 'attachments.pageInfo.hasNextPage');

  const loadNextPage =
    data && data.attachments && hasNextPage && !isFetchingMore
      ? async () => {
          setIsFetchingMore(true);
          await fetchMore({
            variables: {
              cursor: data.attachments.pageInfo.endCursor,
            },
            updateQuery: (prev, { fetchMoreResult }) =>
              updateQuery('attachments', prev, fetchMoreResult),
          });
          setIsFetchingMore(false);
        }
      : () => {};

  const loadNewContent = () => {
    if (isEqual(filter, defaultFilter)) {
      refetch({ filter: defaultFilter });
    } else {
      updateFilter(defaultFilter, { debounce: false });
    }
  };

  const attachments: Attachment[] =
    data && data.attachments ? mapNodes(data.attachments.edges) : [];

  const totalCount = data && data.attachments ? data.attachments.totalCount : 0;

  const maxCount = data?.attachments?.maxCount;

  const loadedCount = attachments.length;

  const ids = attachments.map((a) => a.id);

  const selectable = useSelectable(ids);

  const selectedAttachments = attachments.filter((a) =>
    includes(selectable.selectedIds, a.id)
  );

  return (
    <FilterContext.Provider value={{ filter, debouncedFilter, updateFilter }}>
      <SelectableContext.Provider value={selectable}>
        <IndexPage
          isFiltered={isFiltered}
          searchProps={{
            value: filter.query,
            onChange: (val) => updateFilter({ query: val }),
            placeholder: t('MediaLibrary__SearchPlaceholder'),
          }}
          renderFilters={
            <MediaLibraryFilters
              isFiltered={isFiltered}
              defaultFilter={defaultFilter}
              loading={filterQueryResult.loading}
              data={filterQueryResult.data}
              filter={filter}
              updateFilter={updateFilter}
            />
          }
          renderSubNav={<MediaLibrarySubNavBar />}
        >
          <IndexPage.StickyTopBar
            msgPrefix="MediaLibrary"
            loading={loading}
            totalCount={totalCount}
            maxCount={maxCount}
            loadedCount={loadedCount}
            selectedCount={selectedAttachments.length}
            selectAll={selectable.selectAll}
            selectNone={selectable.selectNone}
            renderGlobalActions={
              <>
                <MediaLibraryStorage />
                <MediaLibraryUploadButton />
              </>
            }
            renderActions={
              <MediaLibraryMassActions
                selectedAttachments={selectedAttachments}
                showAddToAttachmentAlbum={true}
              />
            }
            renderFilterPills={
              <MediaLibraryFilterPills
                loading={filterQueryResult.loading}
                filterData={filterQueryResult.data}
                filter={filter}
                defaultFilter={defaultFilter}
                isFiltered={isFiltered}
                updateFilter={updateFilter}
              />
            }
          />
          {loading && !attachments.length ? (
            <Loader />
          ) : error || filterQueryResult.error ? (
            <ErrorDebug error={error || filterQueryResult.error} />
          ) : (
            <>
              <div className="relative flex items-start justify-center pt-1">
                <div className="absolute left-0 top-0">
                  <NewContentNotification
                    onRefresh={loadNewContent}
                    canAutoRefresh={isEqual(filter, defaultFilter)}
                  />
                </div>

                <Form.ToggleGroup variant="white">
                  <Form.ToggleGroup.Option
                    isSelected={thumbnailStyle === 'full'}
                    onSelect={() => handleUpdateThumbnailStyle('full')}
                    icon={
                      thumbnailStyle === 'full'
                        ? IconGridFluid
                        : IconGridFluidOutline
                    }
                    title={t('MediaLibrary__FullView')}
                    hideTitle
                  />

                  <Form.ToggleGroup.Option
                    isSelected={thumbnailStyle === 'compact'}
                    onSelect={() => handleUpdateThumbnailStyle('compact')}
                    icon={
                      thumbnailStyle === 'compact' ? IconGrid : IconGridOutline
                    }
                    title={t('MediaLibrary__CompactView')}
                    hideTitle
                  />
                </Form.ToggleGroup>
              </div>

              {networkStatus === NetworkStatus.loading ||
              networkStatus === NetworkStatus.setVariables ? (
                <Loader />
              ) : attachments.length ? (
                <AttachmentList
                  attachments={attachments}
                  isInitialLoad={false}
                  isFetchingMore={
                    isFetchingMore || networkStatus === NetworkStatus.refetch
                  }
                  loadNextPage={loadNextPage}
                  hasNextPage={hasNextPage}
                  thumbnailStyle={thumbnailStyle}
                />
              ) : (
                <MediaLibraryEmptyState
                  updateFilter={() => updateFilter(defaultFilter)}
                  hasAttachments={!isEqual(filter, defaultFilter)}
                />
              )}
            </>
          )}
        </IndexPage>

        <Route path={routes.mediaLibrary.show} component={AttachmentModal} />
      </SelectableContext.Provider>

      <BackToTopButton />
    </FilterContext.Provider>
  );
}
