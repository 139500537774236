import React from 'react';
import { NetworkStatus } from 'apollo-client';
import { mapNodes, updateQuery } from 'helpers';
import { get, includes, isEqual, without } from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import ReactDOM from 'react-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import styled from 'styled-components';
import { Button, ErrorDebug, Loader } from 'components';
import Search from 'components/Search';
import FILTER_QUERY from 'graphql/queries/MediaLibraryFilterData.graphql';
import ATTACHMENTS_QUERY from 'graphql/queries/attachments.graphql';
import {
  useAddSuggestAttachmentsToExistingBatch,
  useDebouncedWindowDimensions,
  useFilterManager,
  useIntl,
  useIsMobile,
  useSelectable,
} from 'hooks';
import { IconClose, IconFilterV2 } from 'icons';
import { useParams } from 'react-router-dom';
import {
  FilterContext,
  SelectableContext,
} from 'screens/MediaLibrary/screens/MediaLibraryIndex';
import { MediaLibraryEmptyState } from 'screens/MediaLibrary/screens/MediaLibraryIndex/components';
import { mixins } from 'styles';
import { colors } from 'styles/theme';
import Attachment from 'types/Attachment';
import MediaLibraryFilter from 'types/MediaLibraryFilter';
import RelayConnection from 'types/RelayConnection';
import {
  MediaLibraryBuilderModalAttachmentsList,
  MediaLibraryBuilderModalFilter,
  MediaLibraryBuilderModalFilterWrapper,
} from './components';
import MediaLibraryFilterData from 'types/MediaLibraryFilterData';

interface Props {
  onRequestClose: () => void;
  selectedIds?: (string | number)[];
}

interface QueryData {
  attachments: RelayConnection<Attachment>;
}

interface QueryVars {
  filter: Partial<MediaLibraryFilter>;
  cursor?: string;
}

const defaultFilter: Readonly<MediaLibraryFilter> = {
  sources: [],
  mediaType: [],
  suggested: '2',
  query: '',
  publishers: [],
  publisherTags: [],
  submitters: [],
  suggesters: [],
  suggestionRecipients: [],
  attachmentAlbums: [],
  suggestionAlbums: [],
  suggesterPublishers: [],
  mediaAppearancesOnly: null,
  noMediaAppearances: null,
  createdAt: {
    dateRange: 'all',
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
  canMonetize: false,
  uploaders: [],
};

function MediaLibraryBuilderModal(props: Props) {
  const { selectedIds = [], onRequestClose } = props;
  const { t } = useIntl();
  const aspectRatio = 0;
  const { width: windowWidth, height: windowHeight } =
    useDebouncedWindowDimensions();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const { batchSuggestionId } = useParams();

  useEffect(() => {
    const calculateDimensions = () => {
      const height = windowHeight - 64;
      const width = Math.min(
        height / (1 * aspectRatio || 0.565) + 8 * 45,
        windowWidth - 64
      );
      return { width, height };
    };

    setDimensions(calculateDimensions());
  }, [aspectRatio, windowWidth, windowHeight]);

  useEffect(() => {
    const close = (event: { keyCode: number }) => {
      if (event.keyCode === 27) {
        onRequestClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [onRequestClose]);

  const filterQueryResult = useQuery<MediaLibraryFilterData>(FILTER_QUERY);

  const { filter, debouncedFilter, updateFilter } = useFilterManager(
    defaultFilter,
    true
  );

  const { data, error, fetchMore, networkStatus } = useQuery<
    QueryData,
    QueryVars
  >(ATTACHMENTS_QUERY, {
    variables: { filter: debouncedFilter },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  // We want to go into loading state for initial load and refetch, but not
  // fetchMore
  const loading = includes([1, 2, 4], networkStatus);

  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const hasNextPage = get(data, 'attachments.pageInfo.hasNextPage');

  const loadNextPage =
    data && data.attachments && hasNextPage && !isFetchingMore
      ? async () => {
          setIsFetchingMore(true);
          await fetchMore({
            variables: {
              cursor: data.attachments.pageInfo.endCursor,
            },
            updateQuery: (prev, { fetchMoreResult }) =>
              updateQuery('attachments', prev, fetchMoreResult),
          });
          setIsFetchingMore(false);
        }
      : () => {};

  const attachments: Attachment[] =
    data && data.attachments ? mapNodes(data.attachments.edges) : [];

  const [sentryRef] = useInfiniteScroll({
    loading: loading || isFetchingMore,
    hasNextPage,
    onLoadMore: loadNextPage,
    disabled: !attachments.length,
  });

  const ids = attachments.map((a) => a.id);

  const selectable = useSelectable(ids);

  const [isOpen, setIsOpen] = useState(false);

  const { isMobile } = useIsMobile();

  const newSuggestionIds = without(selectable.selectedIds, ...selectedIds);
  const newSelectionIds = [...selectedIds, ...newSuggestionIds];

  const { isLoading, suggestAttachments } =
    useAddSuggestAttachmentsToExistingBatch(newSelectionIds);

  const exceedsMaxSuggestionLength = ids.length > 999;

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(true);
    }
  }, [isMobile]);

  function handleOnFilterClose() {
    if (isMobile) {
      setIsOpen(false);
    }
  }

  function handleAddSelected() {
    suggestAttachments({
      variables: { attachmentIds: newSelectionIds, id: batchSuggestionId },
    });
    onRequestClose();
  }

  return ReactDOM.createPortal(
    <Wrapper>
      <div className="Overlay Overlay--after-open" onClick={onRequestClose}>
        <FilterContext.Provider
          value={{ filter, debouncedFilter, updateFilter }}
        >
          <SelectableContext.Provider value={selectable}>
            <div
              className={`boxShadowModal fixed left-1/2 flex -translate-x-1/2 flex-col items-stretch overflow-hidden border-r-4 bg-white ${
                isMobile ? 'top-0' : 'top-4'
              }`}
              onClick={(e) => e.stopPropagation()}
              style={{
                width: `${isMobile ? '100%' : dimensions.width + 'px'}`,
                height: `${isMobile ? '100%' : dimensions.height + 'px'}`,
              }}
            >
              <div className="border-b-default flex justify-between border-grey1 p-3 align-middle text-grey1">
                <h3 className="m-0 font-sans text-20 font-normal">
                  {t('MediaLibraryBuild__title')}
                </h3>

                <button onClick={onRequestClose}>
                  <IconClose className="h-2 w-2" />
                </button>
              </div>

              <div className="overflow-y-auto">
                <>
                  <div className="flex items-stretch overflow-hidden">
                    <MediaLibraryBuilderModalFilterWrapper
                      isOpen={isOpen}
                      onCloseHandler={handleOnFilterClose}
                    >
                      <MediaLibraryBuilderModalFilter
                        loading={filterQueryResult.loading}
                        data={filterQueryResult.data}
                        filter={filter}
                        updateFilter={updateFilter}
                      />
                    </MediaLibraryBuilderModalFilterWrapper>

                    <div className="flex-1 overflow-hidden p-3">
                      <div className="flex items-center">
                        <Search
                          value={filter.query}
                          onChange={(val: string) =>
                            updateFilter({ query: val })
                          }
                          placeholder={t('MediaLibrary__SearchPlaceholder')}
                          searchContainerStyles="bg-grey8 rounded-3xl mb-2 text-grey5 py-1 border-l-0"
                          searchInputStyles="bg-grey8"
                        />
                        {isMobile && (
                          <button
                            className="mb-1.5 pl-1"
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <IconFilterV2 />
                          </button>
                        )}
                      </div>

                      {loading && !attachments.length ? (
                        <Loader />
                      ) : error || filterQueryResult.error ? (
                        <ErrorDebug error={error || filterQueryResult.error} />
                      ) : (
                        <>
                          {networkStatus === NetworkStatus.loading ||
                          networkStatus === NetworkStatus.setVariables ? (
                            <Loader />
                          ) : attachments.length ? (
                            <>
                              <MediaLibraryBuilderModalAttachmentsList
                                attachments={attachments}
                              />
                              {(hasNextPage || isFetchingMore) && (
                                <div ref={sentryRef} className="m-auto">
                                  <Loader />
                                </div>
                              )}
                            </>
                          ) : (
                            <MediaLibraryEmptyState
                              updateFilter={() => updateFilter(defaultFilter)}
                              hasAttachments={!isEqual(filter, defaultFilter)}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              </div>
              {selectable.selectedIds.length > 0 && (
                <div className="ml-31.5 flex justify-end p-2 pr-4.5">
                  <Button
                    filledGreyBg
                    onClick={onRequestClose}
                    className="mr-2 text-grey1"
                    inlineBlock
                  >
                    {t('Global__Cancel')}
                  </Button>
                  <Button
                    onClick={handleAddSelected}
                    filledBg
                    inlineBlock
                    disabled={isLoading || exceedsMaxSuggestionLength}
                  >
                    {t('DraftBuilder__AddSelected--mediaLibrary')}
                  </Button>
                </div>
              )}
            </div>
          </SelectableContext.Provider>
        </FilterContext.Provider>
      </div>
    </Wrapper>,
    document.body
  );
}
const Wrapper = styled.div`
  ${mixins.modal};

  .Overlay {
    background-color: ${rgba(colors.grey2, 0.8)};
  }
`;

export default MediaLibraryBuilderModal;
